@font-face {
  font-family: "QuicksandMedium";
  src: local("QuicksandMedium"),
    url("./fonts/Quicksand-Medium.ttf") format("truetype");
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.access-denied-text {
  font-family: QuickSandMedium;
  color: #242063;
}

.custom-scrollbar::-webkit-scrollbar {
  width: 8px;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #8a8282;
  border-radius: 10px;
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #1f1b55;
  border-radius: 50px;
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #1f1b55;
}

.custom-scrollbar {
  scrollbar-width: thin;
  scrollbar-color: #242063 #f1f1f1;
}
