@import "~antd/dist/antd.css";

.buttons-threeD {
  padding: 5px;
  display: flex;
  flex-direction: row;
}

.volume-measuring-button {
  width: 35px;
  height: 30px;
  margin: 4px;
  z-index: 20;
  background-color: rgb(226, 226, 226);
  border: rgb(226, 226, 226);
  border-radius: 4px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("https://static.thenounproject.com/png/4353-200.png");
  background-size: cover;
  background-attachment: fixed;
}

.volume-compare-button {
  width: 35px;
  height: 30px;
  margin: 4px;
  z-index: 20;
  background-color: rgb(226, 226, 226);
  border: rgb(226, 226, 226);
  border-radius: 4px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-attachment: fixed;
}

.single-cross-section-button {
  width: 35px;
  margin: 4px;
  height: 30px;
  z-index: 20;
  background-color: rgb(226, 226, 226);
  border: rgb(226, 226, 226);
  border-radius: 4px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-attachment: fixed;
}

.cross-section-button {
  width: 35px;
  margin: 4px;
  height: 30px;
  z-index: 20;
  background-color: rgb(226, 226, 226);
  border: rgb(226, 226, 226);
  border-radius: 4px;
  color: grey;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover;
  background-attachment: fixed;
}

.volume-measuring-button-icon {
  width: 30px;
  height: 30px;
  padding: 2px;
}

.twoD-layer-comparoson-icon {
  width: 25px;
  height: 25px;
  padding: 2px;
}

.volume-measuring-button:hover {
  background-color: #48b !important;
  color: white !important;
  border: grey !important;
}

.volume-compare-button:hover {
  background-color: #48b !important;
  color: white !important;
  border: grey !important;
}

.single-cross-section-button:hover {
  background-color: #48b !important;
  color: white !important;
  border: grey !important;
}

.cross-section-button:hover {
  background-color: #48b !important;
  color: white !important;
  border: grey !important;
}

.volume-container {
  background-color: white;
  font-family: QuickSandMedium;
  position: absolute;
  width: 400px;
  height: auto;
  border-radius: 4px;
  z-index: 20;
  left: 4px;
  top: 25px;
  text-align: center;
}

.ant-upload.ant-upload-select {
  width: 100% !important;
}

.volume-container .ant-progress-inner {
  height: 10px !important;
}

#volume-cancel {
  padding: 2px 10px;
  border-radius: 4px;
  width: 100%;
}

.volume-container .ant-progress-success-bg,
.ant-progress-bg {
  height: 10px !important;
}

#compare-button {
  background-color: #343483;
  color: white;
  border-radius: 4px;
  border: #343483;
}

#compare-button:hover {
  background-color: #242063;
  border: #242063;
}

#measureContainer {
  margin: 3;
  margin-left: 12px;
  display: flex;
  align-items: center;
  z-index: 20;
  border-radius: 4px !important;
  width: 430px;
}

.cesium-measure-toolbar {
  width: 40px;
  transition: all 0.5s ease-in-out;
  background-color: rgb(226, 226, 226);
}

.cesium-measure-toolbar.expanded {
  display: flex;
  justify-content: space-between;
  background-color: rgb(207, 207, 207);
}

.cesium-measure-button.active {
  background-color: #1a71cc;
  cursor: default;
  border-radius: 4px;
  width: 40px;
  height: 30px;
  text-align: center;
}

.cesium-measure-button:hover {
  background-color: #48b;
  width: 40px;
  color: white;
  border-radius: 4px;
  height: 30px;
  text-align: center;
}

.cesium-measure-button {
  width: 40px;
  height: 30px;
  vertical-align: top;
  display: inline-block;
  border-right: none !important;
  cursor: pointer;
  padding: 2px 0px !important;
  padding-left: 10px;
  text-align: center;
  color: white !important;
}

.cesium-measure-toolbar .cesium-measure-button {
  display: none;
  width: 40px;
}

.cesium-measure-toolbar .cesium-measure-button-main {
  display: block;
}

.cesium-measure-toolbar.expanded {
  width: 280px;
  display: flex;
  justify-content: space-between;
  background-color: rgb(226, 226, 226);
}

.cesium-measure-toolbar.expanded .cesium-measure-button {
  transition: all 0.3s ease-in-out;
  display: block;
}

.cesium-measure-toolbar.expanded .cesium-measure-instructions {
  background-color: white;
}

.cesium-measure-instructions {
  position: absolute;
  border-radius: 4px;
  top: 50px;
  max-height: auto;
  overflow-y: auto;
  color: black;
  padding: 10px;
  stroke: white;
  z-index: 9999;
  background-color: white;
  stroke-width: 2;
}

.cesium-measure-icon {
  margin-right: 10px;
}

.cesium-measure-instructions .bold {
  margin-bottom: 3px;
}

.cesium-measure-instructions>ul {
  padding-left: 20px;
}

.cesium-measure-toolbar {
  border-radius: 4px;
}

.cross-section-container {
  background-color: rgba(200, 200, 200, 0.3);
  position: absolute;
  width: 100%;
  height: 60%;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  z-index: 20;
  padding: 10px;
  bottom: 0;
  text-align: center;
}

.cross-section-container:hover {
  background-color: #e0e0f5;
  transition: 1s;
  color: black;
}

.cross-section-container:not(:hover) {
  transition: 1s;
}

.chart-container {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center !important;
  text-align: center !important;
  width: 100%;
  height: 100%;
}

.chart-container {
  font-weight: bold;
  padding-top: 10px;
  font-family: QuickSandMedium;
}

.minimize-button {
  font-weight: bold !important;
  float: right;
  border: 1px solid #242063 !important;
  border-radius: 4px;
  font-size: 12px;
  color: #242063 !important;
  font-family: QuickSandMedium;
}

.minimize-button:hover {
  background-color: #242063 !important;
  border: 1px solid #242063 !important;
  color: white !important;
}

.minimize-button:target .cross-section-container {
  height: 100px;
}

.layerTopTab {
  background-color: rgba(200, 200, 200, 0.3);
  height: 50px;
  color: white;
  font-weight: bolder;
  position: absolute;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  z-index: 9999;
  font-family: QuickSandMedium;
  font-size: 13px;
  left: 25%;
  width: 50%;
}

.layerTopTab:hover {
  background-color: white;
  font-family: QuickSandMedium;
  font-size: 13px;
  color: #242063;
  transition: 1s;
  box-shadow: -2px -2px 10px rgba(0, 0, 0, 0.4) !important;
}

.layerTopTab:not(:hover) {
  transition: 1s;
}