@import "~antd/dist/antd.css";

#components-layout-demo-side .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}

body,
html {
  height: 100%;
}

.scrolllist-clients::-webkit-scrollbar {
  width: 5px;
  height: 3px;
  border-radius: 10px;
}

/* Track */
.scrolllist-clients::-webkit-scrollbar-track {
  background: #39348c;
  margin-top: 3px;
  margin-right: 30px !important;
  border-radius: 20px !important;
}

/* Handle */
.scrolllist-clients::-webkit-scrollbar-thumb {
  background: #f1f1f1;
  border-radius: 20px;
}

/* Handle on hover */
.scrolllist-clients::-webkit-scrollbar-thumb:hover {
  background: white;
}

.site-layout .site-layout-background {
  background: #fff;
}

.sidebar-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100%;
  background-color: lightgrey;
}

.organization-profile-icon {
  display: flex;
  justify-content: center;
}

.organization-profile-headers {
  text-align: center;
  line-height: 1.3;
}

.organization-profile-headers h5 {
  color: white;
  font-family: QuickSandMedium;
  text-overflow: ellipsis;
  overflow: hidden;
}

.introjs-tooltip-title {
  color: #242063 !important;
}

.introjs-tooltipReferenceLayer * {
  font-family: QuickSandMedium !important;
}

.cssClassName1 {
  position: relative !important;
  min-width: 400px !important;
}

.introjs-tooltip {
  min-width: 400px !important;
}

.introjs-helperLayer {
  box-shadow: rgba(255, 255, 255, 0.9) 0px 0px 1px 2px,
    rgba(33, 33, 33, 0.6) 0px 0px 0px 5000px !important;
}

.introjs-tooltiptext {
  text-align: center !important;
}

.introjs-button {
  font-size: 11px !important;
  padding: 4px 10px 4px 10px !important;
}

.introjs-nextbutton {
  background-color: #242063 !important;
  color: white !important;
  text-shadow: none !important;
  border: none !important;
}

.ant-layout-header {
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.4) !important;
  padding: 0 10px !important;
  z-index: 1;
  height: auto;
}

.header-content {
  display: flex;
  justify-content: space-between;
}

.header-logo {
  text-align: center;
  height: auto;
  margin-left: 40px;
}

.dashboard-sider {
  min-width: 250px !important;
  background-color: #242464 !important;
}

.ant-menu.ant-menu-dark {
  color: white;
  background-color: #242464 !important;
}

.ant-menu-dark .ant-menu-inline.ant-menu-sub {
  background-color: #242464 !important;
}

.ant-layout-sider-collapsed {
  min-width: 80px !important;
}

.side-menu-headers {
  color: rgba(255, 255, 255, 0.25);
  padding: 2.7rem 1rem 0.75rem;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  right: 16px;
  z-index: 1;
  font-size: 11px;
  font-weight: bold;
  text-transform: uppercase;
}

.trigger {
  padding: 0 0 30px 0;
  font-size: 20px;
  cursor: pointer;
  position: relative;
  float: left;
  height: 0;
  top: 20px;
  color: #242464;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.client-list {
  width: 220px !important;
  min-width: 220px !important;
  min-height: 150px;
  display: inline-block;
  padding: 10px;
  border-radius: 10px !important;
  background-color: #33338c;
}

.scrolllist-clients {
  overflow: scroll;
  height: 150px;
  margin-right: 5px;
  padding: 10px;
  line-height: 20px;
  display: flex;
  overflow-x: hidden;
  flex-direction: column;
  white-space: nowrap;
}

.scrolllist-clients p {
  font-size: 11px;
  text-align: center;
  font-family: QuickSandMedium;
}

#project-names {
  font-size: 12px;
  cursor: pointer;
  color: rgba(255, 255, 255, 0.65);
}

#project-names:hover {
  color: white !important;
}

.client-list-form {
  margin-bottom: 5px !important;
}

.client-list .search-input {
  border-radius: 4px;
}

.ant-menu-dark.ant-menu-dark:not(.ant-menu-horizontal) .ant-menu-item-selected {
  background-color: transparent !important;
}

.list-of-clients {
  color: #8989ab !important;
  padding: 7px 20px;
  position: relative;
  top: 11px;
  font-size: 11px;
  font-weight: bold;
  display: flex;
  flex-direction: column;
  text-decoration: none;
}

.list-of-clients:hover {
  color: white !important;
}

.client-list {
  width: max-content;
  min-width: 250px;
  min-height: 150px;
  display: inline-block;
  border-radius: 10px;
  background-color: #33338c;
  position: relative;
  left: 20px;
}

.middle-logo {
  height: 1.4rem;
  width: 6rem;
}

.middle-header {
  font-size: 17px;
  color: #242063;
  font-weight: bold;
  height: 0;
}

.ant-image .middle-logo {
  display: inline-block !important;
}

.user-detail-dropdown {
  text-align: center;
  cursor: initial;
  margin-top: 10px;
  width: 250px;
}

.user-detail-dropdown p {
  color: grey;
  font-size: 12px;
  font-family: QuicksandMedium;
}

.user-detail-dropdown h4 {
  margin-top: 5px;
  font-weight: bold;
  font-family: QuicksandMedium;
}

.ant-dropdown-menu-title-content {
  background-color: white;
  cursor: initial;
}

.ant-dropdown-menu-item {
  padding: 0 !important;
}

.logout-button {
  padding: 5px 20px;
  font-size: 12px;
}

.logout-button:hover {
  background-color: rgb(240, 240, 240);
  border-radius: 4px;
  cursor: pointer;
}

.edit-profile:hover {
  color: grey;
  cursor: pointer;
}

.edit-organisation-container .ant-drawer-body {
  padding: 0;
  transition: all 1s ease-in-out;
  font-family: QuickSandMedium;
  background-color: #f0f2f5;
}

.edit-organisation-profile {
  display: flex;
  flex-direction: column;
}

.edit-organisation-profile-header {
  background-color: #242063;
  color: white;
  font-size: 14px;
  font-family: QuickSandMedium;
  height: 100px;
  width: 100%;
  display: inline-block;
  padding: 10px;
  position: relative;
}

.edit-organisation-profile-body {
  background-color: #f0f2f5;
  height: 300px;
  font-family: QuickSandMedium;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-organisation-profile-footer {
  position: absolute;
  bottom: 0;
  padding: 10px;
  text-align: end;
  width: 100%;
}

.organisation-photo-container {
  height: 200px;
  cursor: pointer;
  width: 200px;
  object-fit: cover;
  border-radius: 100% !important;
}

.hover-photo {
  cursor: pointer;
  visibility: hidden;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  height: 200px;
  flex-direction: column;
  font-size: 16px;
  width: 200px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  bottom: 100%;
  border-radius: 100%;
  position: relative;
  color: white;
}

.another-div {
  height: 200px;
  border-radius: 100%;
  width: 200px;
}

.another-div:hover .hover-photo {
  visibility: visible;
  transition: 1s ease-in-out;
}

.edit-profile-icon:hover {
  color: #242063;
}