@import "~antd/dist/antd.css";

.delete-alert-container {
  font-family: QuickSandMedium;
  text-align: center;
}
.delete-alert-container span {
  font-family: QuickSandMedium;
}
.delete-alert-button {
  width: 60%;
  background-color: #dc3545 !important;
  border: #dc3545 !important;
  color: white !important;
  border-radius: 4px;
}
.delete-alert-button:hover {
  background-color: #c02e3d !important;
  color: white !important;
}
