@import "~antd/dist/antd.css";

.form .ant-row {
  display: block !important;
}

.edit-profile-container {
  position: absolute;
  right: 10px;
  top: 68px;
  font-family: QuickSandMedium;
  border-radius: 4px;
  z-index: 20;
}

.login-button {
  background-color: #242063 !important;
  color: white !important;
  border: none !important;
  border-radius: 4px;
  font-family: QuicksandMedium;
}

.login-button:hover {
  background-color: #1d1a50 !important;
}

.add-extra-features-button:hover {
  background-color: #eaeaea !important;
}

.edit-profile-container .ant-modal-body {
  padding: 0px 15px 1px 15px;
  margin-bottom: 10px !important;
}

.others .ant-row {
  display: flex !important;
}

.reply-modal .ant-modal-body {
  padding: 0px 15px 10px 15px;
}

.edit-profile-container .ant-modal-close-x {
  color: rgb(100, 100, 100) !important;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-profile-body {
  height: 180px;
  position: relative;
  font-family: QuickSandMedium;
  display: flex;
  justify-content: center;
  align-items: center;
}

.edit-profile-photo-container {
  height: 150px;
  width: 150px;
  cursor: pointer;
  border-radius: 100% !important;
  border: 0 0 0 1px lightgray;
}

.hover-photo-user {
  cursor: pointer;
  visibility: hidden;
  display: flex;
  background-color: rgba(0, 0, 0, 0.4);
  height: 150px;
  flex-direction: column;
  font-size: 12px;
  width: 150px;
  justify-content: center;
  align-items: center;
  z-index: 1;
  bottom: 100%;
  border-radius: 100%;
  position: relative;
  color: white;
}

.edit-profile-cursor {
  height: 150px;
  border-radius: 100%;
  width: 150px;
}

.edit-profile-cursor:hover .hover-photo-user {
  visibility: visible;
  transition: 1s ease-in-out;
}

.custom-tooltip .ant-tooltip-inner {
  background-color: white;
  border: 1px solid #dc3545;
  color: #dc3545;
  border-radius: 5px;
}