@import "~antd/dist/antd.css";

.assign-container .ant-divider-horizontal {
  margin: 1px 0px 20px 0px;
}
.assign-container h2 {
  text-align: center;
  font-family: QuickSandMedium;
  margin-bottom: 20px;
}
.assign-container .ant-select .ant-select-selector {
  border-radius: 4px;
}
.remove-member {
  border: 1px solid #dc3545 !important;
  width: auto;
  border-radius: 4px;
  color: #dc3545 !important;
}
.add-member-button {
  background-color: #242063 !important;
  width: auto;
  border-radius: 4px;
  color: white !important;
  font-family: QuickSandMedium;
}
.add-member-button:hover {
  background-color: #1f1b52 !important;
  border: 1px solid #1f1b52 !important;
}
.remove-member:hover {
  background-color: #dc3545 !important;
  border: 1px solid #dc3545 !important;
  color: white !important;
}
