@import "~antd/dist/antd.css";

.menu-options {
  display: flex;
  flex-direction: column;
  width: auto;
  font-family: QuickSandMedium;
}

.menu-options span {
  border-radius: 4px;
  padding: 4px;
  cursor: pointer;
  font-size: 12px;
}

.menu-options div {
  width: 100%;
}

.download-button {
  color: #888;
}

.download-button:hover {
  background-color: #f1f1f1;
  color: #888;
}

.upload-drive-link-button {
  color: #888;
}

.upload-drive-link-button:hover {
  background-color: #f1f1f1;
  color: #242063;
}

.delete-button {
  color: #888;
}

.delete-layer-button:hover {
  background-color: #eb4032;
  color: white;
  width: 100%;
}

.delete-button:hover {
  background-color: #f1f1f1;
  color: #eb4032;
}

.reject-button {
  color: #888;
}

.reject-button:hover {
  background-color: #f1f1f1;
  color: #eb4032;
}

.submit-button {
  color: #888;
}

.submit-button:hover {
  background-color: #f1f1f1;
  color: #242063;
}

.approve-button {
  color: #888;
}

.approve-button:hover {
  background-color: #f1f1f1;
  color: #52c41a;
}

.ant-popover-inner-content {
  padding: 5px !important;
}

.add-dropdown-container {
  text-align: center;
  cursor: initial;
  width: 140px !important;
}

.add-dropdown-container div {
  color: grey;
  font-size: 13px;
  font-family: QuicksandMedium;
}

.add-dropdown-container div:hover {
  border-radius: 4px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.add-dropdown-container-drive {
  text-align: center;
  cursor: initial;
  width: 180px !important;
}

.add-dropdown-container-drive div {
  color: grey;
  font-size: 13px;
  font-family: QuicksandMedium;
}

.add-dropdown-container-drive div:hover {
  border-radius: 4px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.upload-hover:hover .file-icon-hover {
  color: #242063 !important;
}

.upload-hover:hover .folder-icon-hover {
  color: #fbb31c !important;
}

.upload-hover:hover .timeline-icon-hover {
  color: #49aebe !important;
}

.timeline-menu-container {
  text-align: center;
  padding: 2px;
  cursor: initial;
  font-family: QuicksandMedium;
}

.timeline-menu-container div {
  display: flex;
  justify-content: center;
  color: grey;
  font-size: 11px;
}

.timeline-menu-container div:hover {
  border-radius: 4px;
  background-color: #f1f1f1;
  cursor: pointer;
}

.layer-menu-container {
  text-align: center;
  padding: 2px;
  cursor: initial;
  font-family: QuicksandMedium;
}

.layer-menu-container div {
  display: flex;
  justify-content: center;
  color: grey;
  font-size: 11px;
}

.layer-menu-container div:hover {
  border-radius: 4px;
  background-color: #242063;
  cursor: pointer;
}

.timeline-button {
  color: grey;
  font-size: 13px !important;
  width: 100%;
}

.timeline-button:hover {
  color: grey;
  background-color: transparent;
}

.layer-button {
  color: grey;
  font-size: 13px !important;
}

.layer-button:hover {
  color: white;
  background-color: transparent;
}

.ant-modal-wrap {
  pointer-events: none;
}

.download-progress-bar-modal {
  display: flex;
  height: 100% !important;
  width: 100% !important;
  justify-content: end;
  align-items: flex-end !important;
  position: absolute;
  top: -10px;
  right: 0;
  bottom: 0 !important;
}

.download-progress-bar-modal .ant-modal-body {
  width: 400px;
}