@import "~antd/dist/antd.css";

.progress-bar-container {
  font-family: QuickSandMedium;
  text-align: center;
}

.progress-bar-container span {
  font-family: QuickSandMedium;
}

.cancel-upload {
  background-color: #242063 !important;
  color: white !important;
  border-radius: 4px;
  font-size: 13px;
  padding: 3px 10px;
  height: auto;
  font-family: QuicksandMedium;
  border: none;
}

.cancel-upload:hover {
  background-color: #1d1a50 !important;
}

.moreInfo-arrow-icon .ant-menu-submenu-arrow {
  left: 5px;
}

.moreInfo-arrow-icon .ant-menu-inline {
  border: none;
}

.ant-progress-text {
  position: relative;
  right: 1px;
}

.ant-progress-show-info .ant-progress-outer {
  width: 98%;
  float: left;
}

.loading-container {
  display: flex;
  justify-content: center !important;
  align-items: center !important;
  height: 100vh;
}

.loading-content {
  width: 20%;
  text-align: center;
}

.intro-loading {
  color: #242063;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active {
  color: currentColor !important;
}

.ant-menu-submenu-selected {
  color: none !important;
}

.ant-menu-light .ant-menu-item:hover,
.ant-menu-light .ant-menu-item-active {
  color: currentColor !important;
}

.uploaded-files-list {
  overflow: auto;
  height: 150px;
  line-height: 100px;
  overflow-x: hidden;
  flex-direction: column;
  white-space: nowrap;
}

.uploaded-files-list:hover .ant-menu-submenu-arrow {
  color: black !important;
  /* Use the same color as the parent element */
}
.metric-loader {
  background: #ededed;
  width: 84vw;
  height: 90vh;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  position: fixed;
  z-index: 999999999;
  top: 74px;
  left: 259px;
  border-radius: 8px;
}

.metric-loader p {
  color: #242063;
  font-weight: bold;
  margin-top: 1rem;
}


.mertic-error-upload-image {
  margin-bottom: 0.5rem;
}
.mertic-error-upload-new-image {
  background-color: blue;

  position: absolute;
  z-index: 9999999;
  top: -9px;
  left: 0;

  position: absolute;
  z-index: 9999999;
  top: -9px;
  left: 0;
}
